table {
    width: 100%;
    border-collapse: collapse;
}

tr th, tr td {
    border: 0.5px solid #D0DAF7;
}

tr th {
    background: #EAEFF4;
    padding: 4px 10px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #91A0C6;
    text-align: center;
}

tr td {
    padding: 15px 3px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    max-width: 43px;
    color: #91A0C6;
    overflow: hidden;
    text-overflow: ellipsis;
    background: linear-gradient(180deg, #F9FBFD 0%, #F6FBFF 100%);
}

tr td:first-child {
    padding: 11px 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #5068A5;
    min-width: 163px;
}

tr td:first-child, tr th:first-child {
    text-align: left;
}

.color-start{
    color: #45c528;
}

.color-stop{
    color: #c52828;
}

.medication{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.rotate45 {
    transform: rotate(225deg);
}