.BaseDatePicker .MuiInputBase-root {
    background: linear-gradient(180deg, #F9FBFD 0%, #F6FBFF 100%);
}

.BaseDatePicker .MuiIconButton-root > svg {
    font-size: 1.1rem !important;
}

.BaseDatePicker .MuiInputBase-root input {
    width: calc(100% - 20px) !important;
    padding: 5px 10px !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 24px !important;
    color: #5068A5 !important;
}