.App {
    font-family: Roboto;
}

.customBlockSwitch > .MuiFormGroup-root > div {
    justify-content: center;
}

.nextBlock {
    margin-top: 40px;
    margin-bottom: 40px;
}

.btn-svg {
    min-width: 0 !important;
    padding: 0 !important;
    border-radius: 100% !important;
    transition: .3s !important;
    color: #91A0C6 !important;
}

.ml-10 {
    margin-left: 10px !important;
}

h4 {
    margin: 0;
    text-align: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #5068A5;

}

.nextBlock .MuiAccordionDetails-root{
    padding: 0!important;
}

.nextBlock .MuiPaper-root{
    background: transparent !important;
    box-shadow: none !important;
}
.nextBlock .Mui-expanded{
    margin: 0 !important;
}
