.App {
    max-width: 860px;
    margin: auto;
}

.patient-info {
    width: calc(100% - 36px);
    background: linear-gradient(92.04deg, #F2F5FC 17.38%, #EDF1FA 91.35%);
    border-radius: 10px;
    padding: 18px;
}

.field .MuiInputBase-root {
    background: linear-gradient(180deg, #F9FBFD 0%, #F6FBFF 100%);
}

.field .MuiInputBase-root input {
    width: calc(100% - 20px) !important;
    padding: 5px 10px !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 24px !important;
    color: #5068A5 !important;
}

.patient-container {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(12,1fr);
    grid-template-areas:
    "A A A A  B B  C C C  D D D"
    "E E E E  G G  F F F  K K K"
    "L L L  M M M  N N N  O O O"
    "P P P P P P  R R R  S S S"
    "T T T T T T  U U U U U U";
    column-gap: 20px;
    align-items: center;
}

.patient-container .box-a {
    grid-area: A;
}

.patient-container .box-b {
    grid-area: B;
}

.patient-container .box-c {
    grid-area: C;
}

.patient-container .box-d {
    grid-area: D;
}

.patient-container .box-e {
    grid-area: E;
}

.patient-container .box-f {
    grid-area: F;
}

.patient-container .box-g {
    grid-area: G;
}

.patient-container .box-k {
    grid-area: K;
}

.patient-container .box-l {
    grid-area: L;
}

.patient-container .box-m {
    grid-area: M;
}

.patient-container .box-n {
    grid-area: N;
}

.patient-container .box-o {
    grid-area: O;
}

.patient-container .box-p {
    grid-area: P;
}

.patient-container .box-r {
    grid-area: R;
}

.patient-container .box-s {
    grid-area: S;
}

.patient-container .box-t {
    grid-area: T;
}

.patient-container .box-u {
    grid-area: U;
}

.hr-dashed {
    margin: 6px 0 20px 0;
    padding: 0;
    height: 0;
    border: none;
    border-top: 1px dashed #D0DAF7;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #91A0C6;
}

input:-moz-placeholder, textarea:-moz-placeholder {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #91A0C6;
}

input, textarea, select {
    background: linear-gradient(180deg, #F9FBFD 0%, #F6FBFF 100%);
    border: 1px solid #D0DAF7;
    border-radius: 8px;
    width: calc(100% - 20px);
    padding: 5px 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #5068A5;
}

textarea {
    height: 59px;
    resize: none;
}

h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #5068A5;
}

h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #91A0C6;
    margin: 0;
}

.field {
    position: relative;
}

.field span.label {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: #5068A5;
    opacity: 0.6;
    display: block;
    margin-bottom: 8px;
}