.customStyleSelect{
    width: calc(100% - 20px) !important;
    padding: 5px 10px !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 24px !important;
    color: #5068A5 !important;
}

.MuiSelect-select{
    min-height: auto !important;
    background: linear-gradient(180deg, #F9FBFD 0%, #F6FBFF 100%) !important;
}

.MuiOutlinedInput-root fieldset{
    border-radius: 8px !important;
    border: 1px solid #D0DAF7 !important;
}

.customStyleSelect em{
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #91A0C6;
}